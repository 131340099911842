import styled from "styled-components"

export const SidebarWrap = styled.aside`
  padding: 30px 0 100px;
  height: 100%;
`
export const SidebarList = styled.div`
  font-size: 18px;
  font-weight: 600;
  li {
    padding-bottom: 10px;
    margin-bottom: 16px;
    &:not(:last-child) {
      border-bottom: 1px solid ${props => props.theme.colors.borderColor};
    }
    &:before,
    &:after {
      font-size: 18px;
    }
  }
  a {
    font-size: 18px;
    font-weight: 600;
  }
`
export const CategoriasHijo = styled.ul`
  margin-left: 20px;
  margin-top: 10px;
  list-style: none;
`

export const CategoriaPadre = styled.li`
  display: list-item;
`
export const ListaDeCategorias = styled.ul`
  list-style: none;
`
