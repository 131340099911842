import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { ListadoWrapper } from "./style"
import Pagination from "../../../components/pagination"
import { Container, Row, Col } from "../../../components/UI/wrapper"
import BoxLargeImage from "../../../components/box-large-image/layout-two"
import PropTypes from "prop-types"

const Listado = ({ linkStyle, boxStyles }) => {
  const CatalogQuery = useStaticQuery(graphql`
    query {
      allPrismicProducto(limit: 12) {
        totalCount
        edges {
          node {
            id
            data {
              contenido {
                text
              }
              titulo {
                text
              }
              foto {
                fixed(width: 200, height: 200) {
                  ...GatsbyPrismicImageFixed
                }
              }
              marca {
                document {
                  ... on PrismicMarca {
                    data {
                      marca {
                        text
                      }
                    }
                  }
                }
              }
            }
            uid
          }
        }
      }
    }
  `)

  const products = CatalogQuery.allPrismicProducto.edges
  const { totalCount } = CatalogQuery.allPrismicProducto
  const productsPerPage = 12
  const numberOfPages = Math.ceil(totalCount / productsPerPage)
  return (
    <ListadoWrapper>
      <Container>
        <Row>
          {products.map((item, index) => (
            <Col lg={4} md={6} className="box-item" key={`box-image-${index}`}>
              <BoxLargeImage
                {...boxStyles}
                imageSrc={item.node.data.foto.fixed.src}
                btnText={item.node.data.titulo.text}
                desc={item.node.data.marca.document.data.marca.text.replace(
                  /^\w/,
                  c => c.toUpperCase()
                )}
                path={`/productos/${item.node.uid}`}
              />
            </Col>
          ))}
        </Row>
        <Row>
          <Pagination
            rootPage="/productos"
            currentPage={1}
            numberOfPages={numberOfPages}
          />
        </Row>
      </Container>
    </ListadoWrapper>
  )
}

Listado.propTypes = {
  linkStyle: PropTypes.object,
  boxStyles: PropTypes.object,
}

Listado.defaultProps = {
  boxStyles: {
    headingStyle: {
      color: "#000",
    },
  },
  linkStyle: {
    layout: "underline",
    fontSize: "18px",
    fontWeight: 500,
    lineheight: 1.4,
    color: "primary",
    hover: {
      layout: 2,
    },
  },
}

export default Listado
