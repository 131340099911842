import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import {
  SidebarWrap,
  SidebarList,
  CategoriaPadre,
  CategoriasHijo,
  ListaDeCategorias,
} from "./style"

const Sidebar = () => {
  const Data = useStaticQuery(graphql`
    query {
      allPrismicCategoria(sort: { fields: data___nombre___text }) {
        totalCount
        edges {
          node {
            data {
              nombre {
                text
              }
              categoria_padre {
                document {
                  ... on PrismicCategoria {
                    data {
                      nombre {
                        text
                      }
                    }
                    uid
                  }
                }
              }
            }
            uid
          }
        }
      }

      allPrismicProducto {
        edges {
          node {
            data {
              categoria {
                document {
                  ... on PrismicCategoria {
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const products = Data.allPrismicProducto.edges

  const getMenuData = categories => {
    const data = []
    //Primero las categorias padre
    categories.forEach(category => {
      if (!category?.node?.data?.categoria_padre?.document) {
        const uid = category?.node?.uid
        const text = category?.node?.data.nombre.text
        const indice = products.findIndex(
          item => item.node.data.categoria.document.uid === uid
        )
        console.log(indice)
        if (uid && text) {
          if (indice > -1) {
            data.push({ path: uid, title: text, disabled: false })
          } else {
            data.push({ path: uid, title: text, disabled: true })
          }
        }
      }
    })
    //ahora las categorias hijo
    categories.forEach(category => {
      if (category?.node?.data.categoria_padre?.document) {
        const parent = category?.node?.data.categoria_padre?.document?.uid
        const uid = category?.node?.uid
        const text = category?.node?.data?.nombre?.text
        const index = data.findIndex(item => item.path === parent)
        if (index > -1) {
          if (data[index]?.submenu && data[index]?.submenu?.length) {
            data[index].submenu.push({ path: uid, title: text, disabled: true })
          } else {
            data[index].submenu = []
            data[index].submenu.push({ path: uid, title: text, disabled: true })
          }
        }
      }
    })

    return data
  }
  const menuData = getMenuData(Data.allPrismicCategoria.edges)
  return (
    <SidebarWrap>
      <SidebarList>
        <ListaDeCategorias>
          {menuData.map(item => (
            <>
              {item?.submenu ? (
                <>
                  <CategoriaPadre>
                    {item.disabled ? (
                      item.title
                    ) : (
                      <Link href={`/categoria/${item.path}`}>{item.title}</Link>
                    )}
                    <CategoriasHijo>
                      {item?.submenu.map(subItem => (
                        <li>
                          <Link href={`/categoria/${subItem.path}`}>
                            {subItem.title}
                          </Link>
                        </li>
                      ))}
                    </CategoriasHijo>
                  </CategoriaPadre>
                </>
              ) : (
                <CategoriaPadre>
                  {item.disabled ? (
                    item.title
                  ) : (
                    <Link href={`/categoria/${item.path}`}>{item.title}</Link>
                  )}
                </CategoriaPadre>
              )}
            </>
          ))}
        </ListaDeCategorias>
      </SidebarList>
    </SidebarWrap>
  )
}

export default Sidebar
