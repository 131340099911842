import styled from "styled-components"
import { device } from "../../../theme"

export const ListadoWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  @media ${device.medium} {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  @media ${device.small} {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .box-item {
    margin-top: 30px;
  }
`
