import React from "react"
import SEO from "../components/seo"
import PropTypes from "prop-types"
import Sidebar from "../containers/productos/sidebar"
import Listado from "../containers/productos/listado"
import Section, { Row, Col } from "../components/UI/wrapper"

const SeccionProductos = ({
  boxStyles,
  pageContext,
  location,
  ...restProps
}) => {
  const { sectionStyle } = restProps

  return (
    <>
      <SEO title="Productos Seggasa" />
      <main className="site-wrapper-reveal">
        <Section {...sectionStyle}>
          <Row>
            <Col lg={{ span: 4, order: 1 }} xs={{ span: 12, order: 2 }}>
              <Sidebar />
            </Col>
            <Col lg={{ span: 8, order: 2 }} xs={{ span: 12, order: 1 }}>
              <Listado />
            </Col>
          </Row>
        </Section>
      </main>
    </>
  )
}

SeccionProductos.propTypes = {
  sectionStyle: PropTypes.object,
}

SeccionProductos.defaultProps = {
  sectionStyle: {
    pt: "89px",
    pb: "100px",
    responsive: {
      medium: {
        pt: "72px",
        pb: "80px",
      },
      small: {
        pt: "53px",
        pb: "60px",
      },
    },
  },
}

export default SeccionProductos
