import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { MdTrendingFlat } from "react-icons/md"
import Button from "../../UI/button"
import {
  BoxLargeImgWrap,
  BoxLargeImgInner,
  BoxLargeImgMedia,
  BoxLargeImgContent,
  BoxLargeImgBtnWrap,
  TextWrap,
} from "./box-large-image.style"

const BoxLargeImage = ({ imageSrc, path, btnText, desc, ...boxStyles }) => {
  const { boxStyle, contentBoxStyle, btnStyle, descStyle } = boxStyles
  let boxImage
  if (imageSrc.fixed && typeof imageSrc.fixed !== "function") {
    boxImage = <Img fixed={imageSrc.fixed} alt={btnText} />
  } else if (imageSrc.fluid) {
    boxImage = <Img fluid={imageSrc.fluid} alt={btnText} />
  } else {
    boxImage = <img src={imageSrc} alt={btnText} />
  }

  return (
    <BoxLargeImgWrap {...boxStyle}>
      <BoxLargeImgInner>
        {imageSrc && <BoxLargeImgMedia>{boxImage}</BoxLargeImgMedia>}
        <BoxLargeImgContent {...contentBoxStyle}>
          {desc && <TextWrap {...descStyle} content={desc} />}
          <BoxLargeImgBtnWrap>
            <Button
              {...btnStyle}
              className="button"
              to={path}
              icon={<MdTrendingFlat />}
            >
              {btnText}
            </Button>
          </BoxLargeImgBtnWrap>
        </BoxLargeImgContent>
      </BoxLargeImgInner>
    </BoxLargeImgWrap>
  )
}

BoxLargeImage.propTypes = {
  imageSrc: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  path: PropTypes.string,
  btnText: PropTypes.string,
  btnStyle: PropTypes.object,
  desc: PropTypes.string,
  descStyle: PropTypes.object,
}

BoxLargeImage.defaultProps = {
  path: "/",
  mb: "100px",
  btnText: "Llantas",
  btnStyle: {
    backgroundColor: "#08095C",
    iconposition: "right",
    icondistance: "4px",
    varient: "texted",
    hover: "false",
    display: "flex",
    justify: "center",
    alignitems: "center",
    height: "100%",
  },
}

export default BoxLargeImage
